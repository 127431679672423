// ** React Imports
import { createContext, PropsWithChildren, useState } from "react";

interface IState {
  layout: Layout;
  setMenuCollapsed(val: boolean): void;
  setIsHidden(val: boolean): void;
  setContentWidth(val: "full" | "boxed"): void;
}

export interface Layout {
  menuCollapsed: boolean;
  isHidden: boolean;
  contentWidth: string;
}

const initialLayout: Layout = {
  menuCollapsed: false,
  isHidden: false,
  contentWidth: "full"
};

// ** Create Context
const GlobalContext = createContext<IState>({ layout: initialLayout } as any);

const GlobalContextProvider = (props: PropsWithChildren<any>) => {
  const [layout, setLayout] = useState(initialLayout);

  const setMenuCollapsed = (val: boolean) => {
    setLayout({ ...layout, menuCollapsed: val });
  };

  const setIsHidden = (val: boolean) => {
    setLayout({ ...layout, isHidden: val });
  };

  const setContentWidth = (val: "full" | "boxed") => {
    setLayout({ ...layout, contentWidth: val });
  };

  return (
    <GlobalContext.Provider
      value={{ layout, setMenuCollapsed, setIsHidden, setContentWidth }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export { GlobalContextProvider, GlobalContext };
